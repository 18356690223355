import "./Footer.css";

import witanko from "../Media/PNG/witanko-crop.gif";

import dedust from "../Media/LOGO/dedust.png";
import dexscreener from "../Media/LOGO/dexscreener.png";
import telegram from "../Media/LOGO/telegram.png";
import twitter from "../Media/LOGO/x.png";

function Footer() {
  return (
    <>
      <div className="Footer-image">
        <img className="witanko-gif" src={witanko}></img>
        <div className="Footer">
          <div className="Footer-icons">
            <img
              class="socialIcon"
              width="25"
              height="25"
              src={telegram}
              alt="telegramLogo"
              onClick={() => window.open("https://t.me/CubigatorTon")}
            />
            <img
              class="socialIcon"
              width="25"
              height="25"
              src={twitter}
              alt="twitterLogo"
              onClick={() => window.open("https://x.com/CubigatorOnTon")}
            />
            <img
              class="socialIcon"
              width="25"
              height="25"
              src={dedust}
              alt="dedustLogo"
              onClick={() =>
                window.open(
                  "https://dedust.io/swap/TON/EQCXSMNX3OB5jPgOJkEzjYhm7FOBfkA3cg7paBqTYwzXrjIi"
                )
              }
            />
            <img
              class="socialIcon"
              width="25"
              height="25"
              src={dexscreener}
              alt="dexscreenerLogo"
              onClick={() =>
                window.open(
                  "https://dexscreener.com/ton/eqdsaaktf75b75jvp4avzaktqp6jt7qki7b8zvbsn2jkdba5"
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
